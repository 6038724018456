import { Component, OnInit } from '@angular/core';
import { Select, Store, ofActionSuccessful, Actions, ofActionErrored, ofActionDispatched } from '@ngxs/store';
import { AuthenticationState } from '../store/authentication/authentication-state';
import { Observable, Subject } from 'rxjs';
import { TaskState } from '../store/task/task-state';
import { Task } from '../models/task';
import { GetDonorTask, GetAllTask, AcceptTask, CompleteTask, DeleteTask, SendToCDC } from '../store/task/task-actions';
import { UserState } from '../store/user/user-state';
import { User } from '../models/user';
import { Donor } from '../models/donor';
import { DonorState } from '../store/donor/donor-state';
import { takeUntil } from 'rxjs/operators';
import { Leaderboard } from '../store/donor/donor-actions';
import { Router } from '@angular/router';
import { DoneeState } from '../store/donee/donee-state';
import { Donee } from '../models/donee';
import { ApproveUser, DeleteUser, GetAllUser, UpdateUser } from '../store/user/user-actions';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { DeliveryState } from '../store/delivery/delivery-state';
import { Delivery } from '../models/delivery';
import { AcceptDelivery, GetAllDelivery, GetDeliveryList } from '../store/delivery/delivery-actions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
   
  @Select(AuthenticationState.authorization) role$:Observable<string>;
  role: string;

  @Select(AuthenticationState.token) token$:Observable<string>;
  token: string;

  @Select(DonorState.leaderboard) leaderboard$: Observable<any>;
  leaderboard: any[];

  @Select(UserState.user) user$: Observable<User>;
  user: User;

  @Select(TaskState.donorTasks) donorTasks$: Observable<Task[]>;
  donorTasks: Task[];

  @Select(TaskState.inProgressTasks) tasks$: Observable<Task[]>;
  tasks: Task[];

  @Select(TaskState.pendingTasks) pendingTasks$:Observable<Task[]>;
  pendingTasks: Task[];

  @Select(TaskState.donorPoints) points$: Observable<number>;
  points: number;

  @Select(TaskState.completedTasksNumber) completedTasksNumber$: Observable<number>;
  completedTasksNumber: number;

  @Select(TaskState.completedTasks) completedTasks$: Observable<Task[]>;
  completedTasks: Task[];

  @Select(DonorState.donor) donor$: Observable<Donor>;
  donor: Donor;

  @Select(DoneeState.donee) donee$: Observable<Donee>;
  donee: Donee;

  @Select(UserState.users) users$: Observable<User[]>;
  users: User[];

  @Select(DeliveryState.deliveryList) deliveries$:Observable<Delivery[]>;
  deliveries:Delivery[];

  @Select(DeliveryState.completedDeliveries) completedDeliveries$:Observable<Delivery[]>;
  completedDeliveries:Delivery[];

  @Select(DeliveryState.onGoingDeliveries) onGoingDeliveries$:Observable<Delivery[]>;
  onGoingDeliveries:Delivery[];
  
  donorTasksDone: boolean=false;
  allTasksDone: boolean=false;

  objectId: number;
  pendingAction: string;
  confirmationMessage: string;
  confirmationModal: boolean;

  success:boolean=true;
  failure:boolean=true;

  destroy$= new Subject<void>();
  loading: boolean=false;
  statusModal: boolean=false;
  hubConnection: HubConnection;
  form: FormGroup;
  emailModal: boolean;
  allDeliveryListDone: boolean;


   
  constructor(private store:Store, private actions: Actions, private router: Router, private formBuilder: FormBuilder) { 
    this.role$.subscribe( role => this.role=role);
    this.user$.subscribe( user => this.user=user);
    this.donorTasks$.subscribe(donorTasks => this.donorTasks=donorTasks);
    this.points$.subscribe(points => this.points =points);
    this.completedTasksNumber$.subscribe(tasks => this.completedTasksNumber=tasks);
    this.completedTasks$.subscribe(tasks => this.completedTasks=tasks);
    this.donor$.subscribe(donor => this.donor = donor);
    this.donee$.subscribe(donee => this.donee= donee);
    this.leaderboard$.subscribe(leaderboard => this.leaderboard=leaderboard);
    this.tasks$.subscribe(tasks => this.tasks =tasks);
    this.users$.subscribe( user => this.users=user);
    this.pendingTasks$.subscribe(pendingTasks => this.pendingTasks =  pendingTasks)
    this.token$.subscribe(token => this.token=token);
    this.deliveries$.subscribe(delivery => this.deliveries = delivery);
    this.completedDeliveries$.subscribe(delivery => this.completedDeliveries = delivery);
    this.onGoingDeliveries$.subscribe(delivery => this.onGoingDeliveries = delivery);



  }
  
  ngOnDestroy():void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    if(this.role=='Donor')
    this.store.dispatch([new GetDonorTask(), new GetAllTask()],).subscribe(()=>{});

    if(this.role=='Donee')
    this.store.dispatch([new GetAllTask()],).subscribe(()=>{});

    if(this.role=='Admin')
    this.store.dispatch(new GetAllUser());

    if(this.role=='DeliveryPerson')
    this.store.dispatch([new GetDeliveryList(), new GetAllDelivery()]).subscribe(()=>{this.allDeliveryListDone=true;});


    this.store.dispatch(new Leaderboard());

    this.createForm();

    this.actions.pipe(ofActionSuccessful(GetAllTask), takeUntil(this.destroy$)).subscribe(()=>{
 this.allTasksDone=true;})
    this.actions.pipe(ofActionSuccessful(GetDonorTask),takeUntil(this.destroy$)).subscribe(()=>{if(this.role=='Donor')this.donorTasksDone=true;})


   
    this.actions.pipe(ofActionSuccessful(AcceptTask),takeUntil(this.destroy$)).subscribe(()=> {this.success=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;
      this.hubConnection.invoke("SendTaskAccepted", this.personId);
       this.store.dispatch(new GetDonorTask())
       this.store.dispatch(new GetAllTask())
    });
    this.actions.pipe(ofActionErrored(AcceptTask),takeUntil(this.destroy$)).subscribe(()=> {this.failure=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;});
    this.actions.pipe(ofActionDispatched(AcceptTask),takeUntil(this.destroy$)).subscribe(()=> {this.loading=true;this.success=true;this.failure=true;});


    this.actions.pipe(ofActionSuccessful(SendToCDC),takeUntil(this.destroy$)).subscribe(()=> {this.success=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;
      this.hubConnection.invoke("SendTaskAccepted", this.personId);
       this.store.dispatch(new GetDonorTask())
       this.store.dispatch(new GetAllTask())
    });
    this.actions.pipe(ofActionErrored(SendToCDC),takeUntil(this.destroy$)).subscribe(()=> {this.failure=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;});
    this.actions.pipe(ofActionDispatched(SendToCDC),takeUntil(this.destroy$)).subscribe(()=> {this.loading=true;this.success=true;this.failure=true;});

    this.actions.pipe(ofActionSuccessful(DeleteTask),takeUntil(this.destroy$)).subscribe(()=> {this.success=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;
      this.hubConnection.invoke("SendTaskAccepted", this.personId);
       this.store.dispatch(new GetDonorTask())
    });
    this.actions.pipe(ofActionErrored(DeleteTask),takeUntil(this.destroy$)).subscribe(()=> {this.failure=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;});
    this.actions.pipe(ofActionDispatched(DeleteTask),takeUntil(this.destroy$)).subscribe(()=> {this.loading=true;this.success=true;this.failure=true;});



    this.actions.pipe(ofActionSuccessful(CompleteTask),takeUntil(this.destroy$)).subscribe(()=> {this.success=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;
      this.hubConnection.invoke("TaskCompleted", this.personId);
      this.hubConnection.invoke("SendTaskCompleted");
       this.store.dispatch(new GetAllTask())
    });
    this.actions.pipe(ofActionErrored(CompleteTask),takeUntil(this.destroy$)).subscribe(()=> {this.failure=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;});
    this.actions.pipe(ofActionDispatched(CompleteTask),takeUntil(this.destroy$)).subscribe(()=> {this.loading=true;this.success=true;this.failure=true;});
    

    this.actions.pipe(ofActionSuccessful(ApproveUser),takeUntil(this.destroy$)).subscribe(()=> {this.success=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;
      this.store.dispatch(new GetAllUser())
    });
    this.actions.pipe(ofActionErrored(ApproveUser),takeUntil(this.destroy$)).subscribe(()=> {this.failure=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;});
    this.actions.pipe(ofActionDispatched(ApproveUser),takeUntil(this.destroy$)).subscribe(()=> {this.loading=true;this.success=true;this.failure=true;});

    
    this.actions.pipe(ofActionSuccessful(UpdateUser),takeUntil(this.destroy$)).subscribe(()=> {this.success=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;
    this.store.dispatch(new GetAllUser())
    });
    this.actions.pipe(ofActionErrored(UpdateUser),takeUntil(this.destroy$)).subscribe(()=> {this.failure=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;});
    this.actions.pipe(ofActionDispatched(UpdateUser),takeUntil(this.destroy$)).subscribe(()=> {this.loading=true;this.success=true;this.failure=true;});

    this.actions.pipe(ofActionSuccessful(DeleteUser),takeUntil(this.destroy$)).subscribe(()=> {this.success=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;
      this.store.dispatch(new GetAllUser())
      });
      this.actions.pipe(ofActionErrored(DeleteUser),takeUntil(this.destroy$)).subscribe(()=> {this.failure=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;});
      this.actions.pipe(ofActionDispatched(DeleteUser),takeUntil(this.destroy$)).subscribe(()=> {this.loading=true;this.success=true;this.failure=true;});
  
  

      this.actions.pipe(ofActionSuccessful(AcceptDelivery),takeUntil(this.destroy$)).subscribe(()=> {this.success=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;
        this.store.dispatch(new GetDeliveryList())
        });
        this.actions.pipe(ofActionErrored(AcceptDelivery),takeUntil(this.destroy$)).subscribe(()=> {this.failure=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;});
        this.actions.pipe(ofActionDispatched(AcceptDelivery),takeUntil(this.destroy$)).subscribe(()=> {this.loading=true;this.success=true;this.failure=true;});
    
    


    this.hubConnection = new HubConnectionBuilder().withUrl('https://unityugapi.unitygy.com/RealTimeHub',{ accessTokenFactory: () => this.token } ).build();


  
    this.hubConnection.start();
    
  }

  createForm(){
    this.form = this.formBuilder.group({
        email:['',Validators.required]
    })
  }


  confirm(action: string, objectId: number){

    this.objectId=objectId;
    this.pendingAction=action;


    if(action=="acceptTask"){
      this.confirmationMessage="Are you sure you're up to the challenge?";
      this.confirmationModal=true;
    }  

    if(action=="deleteTask"){
      this.confirmationMessage="Are you sure you want to delete this request?";
      this.confirmationModal=true;
    }  
    
    if(action=="completeTask"){
      this.confirmationMessage="Are you sure you want to mark this request as completed?";
      this.confirmationModal=true;
    }  

    if(action=="approveUser"){
      this.confirmationMessage="Are you sure you want to approve this user?";
      this.confirmationModal=true;
    }

    if(action=="deleteUser"){
      this.confirmationMessage="Are you sure you want to delete this user?";
      this.confirmationModal=true;
    }

    if(action=='acceptDelivery'){
      this.confirmationMessage="Are you sure you want to accept this delivery?";
      this.confirmationModal=true;
    }

    if(action=='sendtoCDC'){
      this.confirmationMessage="Are you sure you want to email this task to the CDC?";
      this.emailModal=true;
    }


    }

    sendMessage(id:number, name:string){
      
      this.router.navigate(['/messages/create-message/',name,id])

    }

    scheduleDelivery(id:number,name:string){
      this.router.navigate(['delivery/create/',name,id]);
    }

    editTask(id:number){
     
        this.router.navigate(['/tasks/edit-task/', id]);
  
    }

    personId: number;

    markComplete(id:number, donorId: number){
      this.objectId=id;
      this.personId=donorId;
      this.pendingAction="completeTask";

      this.confirmationMessage="Are you sure you want to mark this request as completed?";
      this.confirmationModal=true;
    }

    acceptTask(id:number, doneeId: number){
      this.objectId=id;
      this.personId=doneeId;

      this.pendingAction="acceptTask";

      this.confirmationMessage="Are you sure you're up to the challenge?";
      this.confirmationModal=true;
    }
 

  performAction(){
    if(this.pendingAction=="acceptTask")
    this.store.dispatch(new AcceptTask(this.objectId));

    if(this.pendingAction=="completeTask"){
      this.store.dispatch(new CompleteTask(this.objectId));
 
    }

    if(this.pendingAction=="deleteTask"){
      this.store.dispatch(new DeleteTask(this.objectId));
 
    }

    if(this.pendingAction=='acceptDelivery'){
    this.store.dispatch(new AcceptDelivery(this.objectId))
    }

    if(this.pendingAction=='sendtoCDC'){
     this.store.dispatch(new SendToCDC({taskId:this.objectId,email:this.form.value.email}))
    }

    if(this.pendingAction=="approveUser")
    this.store.dispatch(new ApproveUser(this.objectId))

    if(this.pendingAction=="deleteUser")
    this.store.dispatch(new DeleteUser(this.objectId))

  }

}


 

