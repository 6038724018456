<clr-main-container>
    <app-header></app-header>
    <div class="content-container" >
        <clr-vertical-nav [clr-nav-level]="2">
            <a clrVerticalNavLink routerLink="/delivery/my-deliveries" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="store" class="is-solid"></clr-icon>
                My Deliveries
            </a>
            <a clrVerticalNavLink routerLink="/delivery/completed-deliveries" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="check" class="is-solid"></clr-icon>
                Completed Deliveries
            </a>

        </clr-vertical-nav>
        <div class="content-area" style="background-image: url('../../assets/deliveries.png');background-repeat: no-repeat;background-size:auto;background-position: center;">
            <router-outlet></router-outlet>
        </div>
    </div>
</clr-main-container>
