import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, Select, Actions, ofActionSuccessful } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { AuthenticationState } from 'src/app/store/authentication/authentication-state';
import { Signout } from 'src/app/store/authentication/authentication-actions';
import { MessageState } from 'src/app/store/message/message-state';
import { Message } from 'src/app/models/message';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DoneeState } from 'src/app/store/donee/donee-state';
import { DeliveryPersonState } from 'src/app/store/delivery-person/delivery-person-state';
import { DeliveryPerson } from 'src/app/models/delivery-person';
import { Donee } from 'src/app/models/donee';
import { Donor } from 'src/app/models/donor';
import { DonorState } from 'src/app/store/donor/donor-state';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { GetAllMessage, UpdateMessage } from 'src/app/store/message/message-actions';
import { Leaderboard } from 'src/app/store/donor/donor-actions';
import { CompleteTask, CreateTask, GetAllTask, GetDonorTask } from 'src/app/store/task/task-actions';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Select(AuthenticationState.id) id$: Observable<number>;
  id:number;
  @Select(AuthenticationState.authorization) role$: Observable<string>;
  role:string;



  destroy$= new Subject<void>();


  @Select(DonorState.donor) donor$: Observable<Donor>;
  @Select(DeliveryPersonState.deliveryPerson) deliveryPerson$: Observable<DeliveryPerson>;
  @Select(DoneeState.donee) donee$: Observable<Donee>;

  donor:Donor;
  donee:Donee;
  deliveryPerson:DeliveryPerson;

  
  finishSignUp=false;
  hubConnection: HubConnection;
  token: string;

  



  ngOnDestroy():void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  @Select(MessageState.messages) messages$: Observable<Message[]>;
  @Select(AuthenticationState.authorization) type$: Observable<string>;
  @Select(AuthenticationState.token) token$: Observable<string>;
  userStatus: boolean;

   messages:Message[];
   unreadMessages:Array<Message>=[];



   

  constructor(private router: Router, private store: Store, private actions:Actions) {
    this.messages$.subscribe(messages=> this.messages=messages);
    this.donee$.subscribe(user => this.donee =user);
    this.donor$.subscribe(user => this.donor = user);
    this.deliveryPerson$.subscribe(user=> this.deliveryPerson=user);
    this.role$.subscribe(role => this.role =role);
    this.id$.subscribe(id => this.id=id);
    this.token$.subscribe(token => this.token = token);
   }


   setRole(){
    this.role= this.store.selectSnapshot(AuthenticationState.authorization);
   }

   signUpComplete(){

    
     if(this.router.url != "/authentication/my-account"){
      if(this.role == "Donor"){
        if(this.donor.modifiedBy==null)
        this.finishSignUp=true;
       }else if(this.role =="Donee"  ){
         if(this.donee.modifiedBy==null)
         this.finishSignUp=true;
       }
     }
   }


   filterMessages(){
     this.unreadMessages=this.messages.filter(message=> !message.isRead);
   }

  logout(){
    this.store.dispatch(new Signout);
    localStorage.removeItem('authentication');
  }

  ngOnInit() {

    this.store.dispatch(new GetAllMessage(this.id))

    this.setRole();
    this.signUpComplete();
    this.actions.pipe(ofActionSuccessful(Signout),takeUntil(this.destroy$)).subscribe(()=> this.router.navigate(['/home']));
    this.actions.pipe(ofActionSuccessful(GetAllMessage),takeUntil(this.destroy$)).subscribe(()=> this.filterMessages());
    this.actions.pipe(ofActionSuccessful(UpdateMessage),takeUntil(this.destroy$)).subscribe(()=> {
      this.store.dispatch(new GetAllMessage(this.id))
    });



  this.hubConnection = new HubConnectionBuilder().withUrl('https://unityugapi.unitygy.com/RealTimeHub',{ accessTokenFactory: () => this.token } ).build();

  this.hubConnection.on("MessageData", (message) => {
    this.store.dispatch(new GetAllMessage(this.id));
  });

  this.hubConnection.on("LeadershipData", (message) => {
    this.store.dispatch(new Leaderboard());
  });

  this.hubConnection.on("TaskAcceptedBroadcastData", (message) => {
    if(this.role=="Donor")
    this.store.dispatch(new GetDonorTask());
    else(this.role=="Donee")
    this.store.dispatch(new GetAllTask());
  });

  this.hubConnection.on("TaskAcceptedMessageData", (message) => {
    this.store.dispatch(new GetAllTask());
    this.store.dispatch(new GetAllMessage(this.id));
  });

  this.hubConnection.on("TaskCreatedBroadcastData", (message) => {
    if(this.role=="Donor")
    this.store.dispatch(new GetDonorTask());
  });

  this.hubConnection.on("TaskCompletedMessageData", (message) => {
    this.store.dispatch(new GetAllTask());
    this.store.dispatch(new GetAllMessage(this.id));
  });

  this.hubConnection.on("TaskCompletedBroadcastData", (message) => {
    this.store.dispatch(new GetAllTask());
    this.store.dispatch(new GetAllMessage(this.id));
  });


  this.hubConnection.start();

      this.actions.pipe(ofActionSuccessful(CompleteTask),takeUntil(this.destroy$)).subscribe(()=> {
      this.hubConnection.invoke("SendTaskCompleted");
    });
    
   }


}
