import { ImageStateModel } from './image-state-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ImageService } from 'src/app/services/image.service';

import { GetAllImage, GetImage, UpdateImage, CreateImage } from './image-actions';
import { tap } from 'rxjs/operators';

@State<ImageStateModel>({
    name:'Image'
  })
@Injectable()
  export class ImageState{
  
    constructor(private service: ImageService){
  
    }
  
    @Selector()
    static images(state:ImageStateModel){
      return state.images.filter( object => object.active==true);
    }

    @Selector()
    static imageMethod(state:ImageStateModel){
      return state.image;
    }
  
  
    @Action(GetAllImage)
    getimages({patchState}:StateContext<ImageStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          images: result
        });
      }));
    }

    @Action(GetImage)
    getimageMethodById({patchState}:StateContext<ImageStateModel>, {id}: GetImage){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          image: result
        });
      }));
    }
  
    @Action(UpdateImage)
    updateimageMethod({dispatch}:StateContext<ImageStateModel>,{update}:UpdateImage
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllImage());
            }
          )
        )
      }

      @Action(CreateImage)
      deleteimage({dispatch, patchState}:StateContext<ImageStateModel>,{create}:CreateImage
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllImage());
              }
            )
          )
        }
  
  
    
  
  }