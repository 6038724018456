import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TimelineComponent } from './timeline/timeline.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { AuthenticationGuard } from './guards/authentication-guard.guard';
import { BadgesComponent } from './badges/badges.component';
import { RulesComponent } from './rules/rules.component';
import { AcceptedComponent } from './delivery/accepted/accepted.component';
import { FailedComponent } from './delivery/failed/failed.component';
import { AuthenticationComponent } from './authentication/authentication/authentication.component';
import { DeliveryComponent } from './delivery/delivery/delivery.component';
import { AdminGuard } from './guards/admin.guard';
import { MessagesComponent } from './messages/messages/messages.component';
import { TasksComponent } from './tasks/tasks/tasks.component';
import { ReferralRoutingModule } from './referrals/referral-routing.module';
import { ReferralsComponent } from './referrals/referrals/referrals.component';

const routes: Routes = [

  {
    path:'timeline',
    component:TimelineComponent,
    canActivate:[AuthenticationGuard]
  },
  {
    path:'home',
    component:LandingPageComponent
  },
  {
    path:'leaderboard',
    component:LeaderboardComponent,
    canActivate:[AuthenticationGuard]
  },
  {
    path:'badges',
    component: BadgesComponent,
    canActivate:[AuthenticationGuard]
  },
  {
    path:'rules',
    component:RulesComponent,
    canActivate:[AuthenticationGuard]
  },
  {
    path:'accepted-delivery',
    component:AcceptedComponent
  },
  {
    path:'failed-delivery',
    component:FailedComponent
  },
  {
    path: 'authentication',  
    component:AuthenticationComponent,
    loadChildren: () => import('./authentication/authentication.module')  
    .then(m => m.AuthenticationModule)  
  },
  {
    path: 'delivery',  
    component:DeliveryComponent,
    loadChildren: () => import('./delivery/delivery.module')  
    .then(m => m.DeliveryModule) ,
    canActivateChild:[AdminGuard],
    data:{role:['Donor','DeliveryPerson']},
  },
  {
    path: 'tasks',  
    component:TasksComponent,
    loadChildren: () => import('./tasks/tasks.module')  
    .then(m => m.TasksModule) ,
    canActivateChild:[AdminGuard],
    data:{role:['Donor','Donee']}, 
  },
  {
    path: 'messages',  
    component:MessagesComponent,
    loadChildren: () => import('./messages/messages.module')  
    .then(m => m.MessagesModule)  
  },
  {
    path: 'referrals',  
    component:ReferralsComponent,
    loadChildren: () => import('./referrals/referrals.module')  
    .then(m => m.ReferralsModule) , 
    canActivateChild:[AdminGuard],
    data:{role:['Donee']},

  },
 
  {
    path:'**',
    redirectTo:'home',
    pathMatch:'full'
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
