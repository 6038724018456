export class GetAddress{
    constructor(public id: number){
    
    }
    
    static readonly type="[Address] GetAddress";
    
    }
    
    export class GetAllAddress{
        constructor(){
        
        }
        
        static readonly type="[Address] GetAllAddress";
        
        }
    
    export class UpdateAddress{
        constructor(public update:any){
    
        }
    
        static readonly type="[Address] UpdateAddress";
    }
        
    export class CreateAddress{
        constructor(public create:any){
    
        }
    
        static readonly type="[Address] CreateAddress";
    }


