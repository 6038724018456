import { DonorStateModel } from './donor-state-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { DonorService } from 'src/app/services/donor.service';

import { GetAllDonor, GetDonor, UpdateDonor, CreateDonor, Leaderboard } from './donor-actions';
import { tap } from 'rxjs/operators';

@State<DonorStateModel>({
    name:'Donor'
  })
@Injectable()
  export class DonorState{
  
    constructor(private service: DonorService){
  
    }
  
    @Selector()
    static donors(state:DonorStateModel){
      return state.donors.filter( object => object.active==true);
    }

    @Selector()
    static donor(state:DonorStateModel){
      return state.donor;
    }

    @Selector()
    static leaderboard(state:DonorStateModel){
      return state.leaderboard;
    }
  
  
    @Action(GetAllDonor)
    getdonors({patchState}:StateContext<DonorStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          donors: result
        });
      }));
    }

    @Action(GetDonor,  {cancelUncompleted: true })
    getdonorById({patchState}:StateContext<DonorStateModel>, {id}: GetDonor){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          donor: result
        });
      }));
    }

    @Action(Leaderboard)
    getLeaderboard({patchState}:StateContext<DonorStateModel>,){
      return this.service.leaderboard().pipe(tap(result => {
        patchState({
          leaderboard: result
        });
      }));
    }
  
    @Action(UpdateDonor)
    updatedonor({dispatch}:StateContext<DonorStateModel>,{update,id}:UpdateDonor
      ){
        return this.service.update(update,id).pipe(
          tap(
            result=>
            {
            
            }
          )
        )
      }

      @Action(CreateDonor)
      deletedonor({dispatch, patchState}:StateContext<DonorStateModel>,{create}:CreateDonor
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                
              }
            )
          )
        }
  
  
    
  
  }