import { ReferralStateModel } from './referral-state-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ReferralService } from 'src/app/services/referral.service';

import { GetAllReferral, GetReferral, UpdateReferral, CreateReferral, DeleteReferral } from './referral-actions';
import { tap } from 'rxjs/operators';

@State<ReferralStateModel>({
    name:'Referral'
  })
@Injectable()
  export class ReferralState{
  
    constructor(private service: ReferralService){
  
    }
  
    @Selector()
    static referrals(state:ReferralStateModel){
      return state.referrals.filter( object => object.active==true);
    }

    @Selector()
    static referral(state:ReferralStateModel){
      return state.referral;
    }
  
  
    @Action(GetAllReferral,  {cancelUncompleted: true })
    getreferrals({patchState}:StateContext<ReferralStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          referrals: result
        });
      }));
    }

    @Action(GetReferral,  {cancelUncompleted: true })
    getreferralById({patchState}:StateContext<ReferralStateModel>, {id}: GetReferral){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          referral: result
        });
      }));
    }

    @Action(DeleteReferral,  {cancelUncompleted: true })
    delete({patchState}:StateContext<ReferralStateModel>, {id}: DeleteReferral){
      return this.service.delete(id).pipe(tap(result => {
        patchState({
          referral: result
        });
      }));
    }
  
    @Action(UpdateReferral)
    updatereferral({dispatch}:StateContext<ReferralStateModel>,{update}:UpdateReferral
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllReferral());
            }
          )
        )
      }

      @Action(CreateReferral)
      deletereferral({dispatch, patchState}:StateContext<ReferralStateModel>,{create}:CreateReferral
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllReferral());
              }
            )
          )
        }
  
  
    
  
  }