export class GetReferral{
    constructor(public id: number){
    
    }
    
    static readonly type="[Referral] GetReferral";
    
    }

    export class DeleteReferral{
        constructor(public id: number){
        
        }
        
        static readonly type="[Referral] DeleteReferral";
        
        }
    
    export class GetAllReferral{
        constructor(){
        
        }
        
        static readonly type="[Referral] GetAllReferral";
        
        }
    
    export class UpdateReferral{
        constructor(public update:any){
    
        }
    
        static readonly type="[Referral] UpdateReferral";
    }


        
    export class CreateReferral{
        constructor(public create:any){
    
        }
    
        static readonly type="[Referral] CreateReferral";
    }


