import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable, fromEvent } from 'rxjs';
import { User } from '../models/user';
import { retry, catchError, mapTo, retryWhen, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http:HttpClient) { }

  errorHandl(error:any) {
  let errorMessage = '';
  if(error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
  } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  console.log(errorMessage);
  return throwError(errorMessage);
}

httpOptions={
  headers: new HttpHeaders({
    'Content-Type':'application/json'
  })
}
private onlineChanges$ = fromEvent(window, 'online').pipe(mapTo(true));
  
get isOnline() {
  return navigator.onLine;
}
  get(id:number):Observable<User>{
    return this.http.get<User>("https://unityugapi.unitygy.com/User/"+id).pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }

  isEmailRegisterd(email:string):Observable<any>{
    return this.http.post<any>('https://unityugapi.unitygy.com/User/email',email,this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  getAll():Observable<User[]>{
    return this.http.get<User[]>("https://unityugapi.unitygy.com/User/").pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }

  update(update: any, id:number):Observable<User>{
    return this.http.patch<User>('https://unityugapi.unitygy.com/User/'+id,JSON.stringify(update),this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  
  delete( id:number):Observable<User>{
    return this.http.delete<User>('https://unityugapi.unitygy.com/User/'+id,this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

    create(create: any):Observable<User>{
      return this.http.post<User>('https://unityugapi.unitygy.com/User/createUser',JSON.stringify(create),this.httpOptions).pipe(
        retry(1),
        catchError(this.errorHandl)
      )
    }

    approve(id:number):Observable<User>{
      return this.http.post<User>('https://unityugapi.unitygy.com/User/activateUser',JSON.stringify({userId:id, approved:true}),this.httpOptions).pipe(
        retry(1),
        catchError(this.errorHandl)
      )
    }



}