import { TaskTypeStateModel } from './task-type-state-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { TaskTypeService } from 'src/app/services/tasktype.service';

import { GetAllTaskType, GetTaskType, UpdateTaskType, CreateTaskType } from './task-type-actions';
import { tap } from 'rxjs/operators';

@State<TaskTypeStateModel>({
    name:'TaskType'
  })
@Injectable()
  export class TaskTypeState{
  
    constructor(private service: TaskTypeService){
  
    }
  
    @Selector()
    static taskTypes(state:TaskTypeStateModel){
      return state.taskTypes.filter( object => object.active==true);
    }

    @Selector()
    static tasktypeMethod(state:TaskTypeStateModel){
      return state.tasktype;
    }
  
  
    @Action(GetAllTaskType)
    gettaskTypes({patchState}:StateContext<TaskTypeStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          taskTypes: result
        });
      }));
    }

    @Action(GetTaskType)
    gettasktypeMethodById({patchState}:StateContext<TaskTypeStateModel>, {id}: GetTaskType){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          tasktype: result
        });
      }));
    }
  
    @Action(UpdateTaskType)
    updatetasktypeMethod({dispatch}:StateContext<TaskTypeStateModel>,{update}:UpdateTaskType
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllTaskType());
            }
          )
        )
      }

      @Action(CreateTaskType)
      deletetasktype({dispatch, patchState}:StateContext<TaskTypeStateModel>,{create}:CreateTaskType
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllTaskType());
              }
            )
          )
        }
  
  
    
  
  }