import { TaskStateModel } from './task-state-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { TaskService } from 'src/app/services/task.service';

import { GetAllTask, GetTask, UpdateTask, CreateTask, GetDonorTask, AcceptTask, CompleteTask, RemoveTaskDonor, DeleteTask, SendToCDC } from './task-actions';
import { tap } from 'rxjs/operators';
import { Task } from 'src/app/models/task';
import { GetDonor } from '../donor/donor-actions';

@State<TaskStateModel>({
    name:'Task'
  })
@Injectable()
  export class TaskState{
  
    constructor(private service: TaskService){
  
    }

    @Selector()
    static tasks(state:TaskStateModel){
      return state.tasks.filter( object => object.active==true);
    }
  
    @Selector()
    static pendingTasks(state:TaskStateModel){
      return state.tasks.filter( object => object.active==true && !object.donor);
    }

    @Selector()
    static completedTasksNumber(state:TaskStateModel){
      return state.tasks.filter( object => object.active==true && object.completionDate).length;
    }

    @Selector()
    static completedTasks(state:TaskStateModel){
      return state.tasks.filter( object => object.active==true && object.completionDate).sort((a,b) => Date.parse(a.completionDate) -Date.parse(b.completionDate));
    }

    @Selector()
    static inProgressTasks(state:TaskStateModel){
      return state.tasks.filter(object => object.active==true && object.donor && !object.completionDate)
    }

    @Selector()
    static currentDoneeTasks(state:TaskStateModel){
      return state.tasks.filter(object => object.active==true && !object.completionDate)
    }



    @Selector()
    static donorTasks(state:TaskStateModel){
      return state.donorTasks.filter( object => object.active==true);
    }

    @Selector()
    static donorPoints(state:TaskStateModel){

      function amount(task: Task){
        return task.points;
      }
      
      function sum(prev, next){
        return prev + next;
      }
      
      return state.tasks.filter(object => object.completionDate != null).map(amount).reduce(sum);
    }

    @Selector()
    static task(state:TaskStateModel){
      return state.task;
    }

  
  
    @Action(GetAllTask,  {cancelUncompleted: true })
    gettasks({patchState}:StateContext<TaskStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          tasks: result
        });
      }));
    }


    @Action(RemoveTaskDonor,  {cancelUncompleted: true } )
    removedonors({patchState}:StateContext<TaskStateModel>,{taskId}:RemoveTaskDonor){
      return this.service.removeDonor(taskId).pipe(tap(result => {
        patchState({
         
        });
      }));
    }

    @Action(DeleteTask,  {cancelUncompleted: true } )
    deleteTask({patchState}:StateContext<TaskStateModel>,{id}:DeleteTask){
      return this.service.delete(id).pipe(tap(result => {
        patchState({
         
        });
      }));
    }

    
    @Action(SendToCDC,  {cancelUncompleted: true })
    sendCDC({patchState}:StateContext<TaskStateModel>, {id}: SendToCDC){
      return this.service.sendToCDC(id).pipe(tap(result => {
        patchState({
          task: result
        });
      }));
    }

    @Action(GetTask,  {cancelUncompleted: true })
    gettaskById({patchState}:StateContext<TaskStateModel>, {id}: GetTask){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          task: result
        });
      }));
    }

    @Action(AcceptTask,  {cancelUncompleted: true })
    acceptTask({patchState, dispatch}:StateContext<TaskStateModel>, {id}: AcceptTask){
      return this.service.accept(id).pipe(tap(result => {
        patchState({
          
        });
        dispatch(new GetDonorTask())
      }));
    }


    @Action(CompleteTask,  {cancelUncompleted: true })
    completeTask({patchState, dispatch}:StateContext<TaskStateModel>, {id}: CompleteTask){
      return this.service.complete(id).pipe(tap(result => {
        patchState({
          
        });

        dispatch(new GetAllTask())
      }));
    }

    @Action(GetDonorTask,  {cancelUncompleted: true })
    gettaskByDonor({patchState}:StateContext<TaskStateModel>){
      return this.service.getByDonor().pipe(tap(result => {
        patchState({
          donorTasks: result
        });
      }));
    }
  
  
    @Action(UpdateTask)
    updatetask({dispatch}:StateContext<TaskStateModel>,{update}:UpdateTask
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
            }
          )
        )
      }

      @Action(CreateTask)
      deletetask({dispatch, patchState}:StateContext<TaskStateModel>,{create}:CreateTask
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllTask());
              }
            )
          )
        }
  
  
    
  
  }