<clr-main-container>
    <app-header></app-header>
    <div class="content-container" >
        <clr-vertical-nav [clr-nav-level]="2" *ngIf="role$ | async">
            <a clrVerticalNavLink routerLink="/tasks/my-tasks" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="new" class="is-solid"></clr-icon>
               Ongoing Requests
            </a>
            <a clrVerticalNavLink routerLink="/tasks/completed-tasks" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="event" class="is-solid"></clr-icon>
               Completed Requests
            </a>
            <a clrVerticalNavLink routerLink="/tasks/create-task"  *ngIf="role =='Donee'" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="list" class="is-solid"></clr-icon>
                Create Requests
            </a>
        </clr-vertical-nav>
        <div class="content-area">
            <router-outlet></router-outlet>
        </div>
    </div>
</clr-main-container>
