export class GetMessage{
    constructor(public id: number){
    
    }
    
    static readonly type="[Message] GetMessage";
    
    }
    
    export class GetAllMessage{
        constructor(public id:number){
        
        }
        
        static readonly type="[Message] GetAllMessage";
        
        }
    
    export class UpdateMessage{
        constructor(public update:any){
    
        }
    
        static readonly type="[Message] UpdateMessage";
    }
        
    export class CreateMessage{
        constructor(public create:any){
    
        }
    
        static readonly type="[Message] CreateMessage";
    }

    export class SentMessages{
        constructor(public id: number){}

        static readonly type="[Message] SentMessages";
    }


