export class GetTaskType{
    constructor(public id: number){
    
    }
    
    static readonly type="[TaskType] GetTaskType";
    
    }
    
    export class GetAllTaskType{
        constructor(){
        
        }
        
        static readonly type="[TaskType] GetAllTaskType";
        
        }
    
    export class UpdateTaskType{
        constructor(public update:any){
    
        }
    
        static readonly type="[TaskType] UpdateTaskType";
    }
        
    export class CreateTaskType{
        constructor(public create:any){
    
        }
    
        static readonly type="[TaskType] CreateTaskType";
    }


