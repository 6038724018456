
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { TimelineComponent } from './timeline/timeline.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationModule } from './authentication/authentication.module';
import { RouterModule } from '@angular/router';
import { NgxsModule, NGXS_PLUGINS } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { unityState } from './store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { TasksModule } from './tasks/tasks.module';
import { ReferralsModule } from './referrals/referrals.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { logoutPlugin } from './store/logout-reducer';
import { ClarityModule } from '@clr/angular';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { MessagesModule } from './messages/messages.module';
import { VerifyUsersComponent } from './verify-users/verify-users.component';
import { BadgesComponent } from './badges/badges.component';
import { DeliveryModule } from './delivery/delivery.module';
import { RulesComponent } from './rules/rules.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';


@NgModule({
  declarations: [
    AppComponent,
    LeaderboardComponent,
    LandingPageComponent,
    TimelineComponent,
    VerifyUsersComponent,
    BadgesComponent,
    RulesComponent,
    SplashScreenComponent,
   
  ],
  imports: [
    AnimateOnScrollModule.forRoot(),
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    ClarityModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    NgxsModule.forRoot(unityState, {
      developmentMode: environment.production
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: !environment.production
    }),
    NgxsStoragePluginModule.forRoot({key:['authentication','Donor','Donee','DeliveryPerson','User']}),
  ],
  providers: [
    {
      provide:HTTP_INTERCEPTORS,
      useClass:TokenInterceptor,
      multi:true
    },
  { provide: HTTP_INTERCEPTORS, 
useClass: ErrorInterceptor,
      multi: true },
      {
        provide: NGXS_PLUGINS,
        useValue: logoutPlugin,
        multi: true
      }  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
