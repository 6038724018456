import { DeliveryStateModel } from './delivery-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetAllDelivery, GetDelivery, UpdateDelivery, CreateDelivery, DeleteDelivery, EvidenceUpload, AcceptDelivery, GetDeliveryList, MarkDelivery, RemoveDeliveryPerson } from './delivery-actions';
import { tap } from 'rxjs/operators';
import { DeliveryService } from 'src/app/services/delivery.service';

@State<DeliveryStateModel>({
    name:'Delivery'
  })
@Injectable()
  export class DeliveryState{
  
    constructor(private service: DeliveryService){
  
    }
  
    @Selector()
    static deliveries(state:DeliveryStateModel){
      return state.deliveries.filter( object => object.active==true);
    }

    @Selector()
    static deliveryList(state:DeliveryStateModel){
      return state.deliveryList.filter( object => object.active==true);
    }

    @Selector()
    static delivery(state:DeliveryStateModel){
      return state.delivery;
    }

    @Selector()
    static completedDeliveries(state:DeliveryStateModel){
      return state.deliveries.filter(delivery => delivery.status?.description == 'Completed');
    }

    @Selector()
    static onGoingDeliveries(state:DeliveryStateModel){
      return state.deliveries.filter(delivery => delivery.status?.description!='Completed');
    }
  
  
    @Action(GetAllDelivery)
    getdeliveries({patchState}:StateContext<DeliveryStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          deliveries: result
        });
      }));
    }

    @Action(GetDeliveryList)
    delivList({patchState}:StateContext<DeliveryStateModel>){
      return this.service.getList().pipe(tap(result => {
        patchState({
          deliveryList: result
        });
      }));
    }


    @Action(GetDelivery)
    getDeliveryById({patchState}:StateContext<DeliveryStateModel>, {id}: GetDelivery){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          delivery: result
        });
      }));
    }
  
    @Action(UpdateDelivery)
    updateDelivery({dispatch}:StateContext<DeliveryStateModel>,{update,id}:UpdateDelivery
      ){
        return this.service.update(update,id).pipe(
          tap(
            result=>
            {
             
            }
          )
        )
      }


      @Action(MarkDelivery)
      markDelivery({dispatch}:StateContext<DeliveryStateModel>,{update}:MarkDelivery
        ){
          return this.service.markDelivered(update).pipe(
            tap(
              result=>
              {
               
              }
            )
          )
        }


        
    @Action(AcceptDelivery)
    acceptDelivery({dispatch}:StateContext<DeliveryStateModel>,{id}:AcceptDelivery
      ){
        return this.service.accept(id).pipe(
          tap(
            result=>
            {
             
            }
          )
        )
      }



        
    @Action(EvidenceUpload)
    evidenceUpload({dispatch}:StateContext<DeliveryStateModel>,{update,id}:EvidenceUpload
      ){
        return this.service.evidence(update,id).pipe(
          tap(
            result=>
            {
             
            }
          )
        )
      }

      @Action(DeleteDelivery)
      deleteDeliverys({dispatch}:StateContext<DeliveryStateModel>,{id}:UpdateDelivery
        ){
          return this.service.delete(id).pipe(
            tap(
              result=>
              {
               
              }
            )
          )
        }


        @Action(RemoveDeliveryPerson)
        removeDeliveryPerson({dispatch}:StateContext<DeliveryStateModel>,{id}:RemoveDeliveryPerson
          ){
            return this.service.removePerson(id).pipe(
              tap(
                result=>
                {
                 
                }
              )
            )
          }
  

      @Action(CreateDelivery)
      deleteDelivery({dispatch, patchState}:StateContext<DeliveryStateModel>,{create}:CreateDelivery
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                
              }
            )
          )
        }
  
    }