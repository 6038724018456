<clr-main-container>
    <clr-header style="background-color:#0D1317;">
        <div class="branding" style="text-align:center;">

                <a href="..." routerLink="/home" class="nav-link">
                   
                    <img src="../../assets/UG cares.png" style="height: inherit;width:auto;padding:5%;">
                   
                </a>
          

        </div>

 
        <div class="header-actions">
            <a href=".." routerLink="/authentication/sign-up" class="nav-link nav-icon-text">
                
                <span class="nav-text">Join Now</span>
            </a>
            <a href=".."  routerLink="/authentication/login" class="nav-link nav-icon-text">
                <clr-icon shape="login"></clr-icon>
                <span class="nav-text">Login</span>
            </a>
        </div>

         </clr-header>

         
        

  

    <div class="content-container">

        <div class="content-area" style="padding:0;background-color:whitesmoke;">

            
            <div class="clr-row clr-justify-content-center" style="padding:5%;min-height:100%;background-size: cover;background-repeat: no-repeat; background-image: url('../../assets/other help.png');">
                <div class=" clr-col-lg-8 clr-align-self-center" style="text-align:center;">
                    <h1 style="font-weight:bolder;font-size:50px;color:white;overflow-wrap: break-word;hyphens: auto;">UG CARES IN COLLABORATION WITH UNITY.GY PROVIDING FLOOD RELIEF</h1>
                    <div class="clr-break-row"></div>
                    <p style="font-size: 20px;color:white;font-weight:bold;">The University of Guyana's UG Cares Foundation is helping those in need amid the current disaster. Connect with the University to request any aid needed to help you survive this crisis.</p>
                    <div class="clr-break-row"></div>
                    <br>
                    <button class="btn" style="color:grey;opacity: 0.7;font-weight:bold;border-color:white;" (click)="signup()">REQUEST HELP</button>
                </div>
                
            </div>

            <div animateOnScroll animationName="animated slideInUp " class="hide-on-init clr-row clr-justify-content-center" style="min-height:100%;padding:5%;text-align:center;background-color: white;">
                <div class="clr-col-lg-7 clr-align-self-center">
                    <h1 style="font-weight:bolder;font-size:50px;text-align: center;">ABOUT US</h1>
                    <br>
        
                    <p style="font-size: 20px;text-align: justify;color:black;">Unity.GY -- a donor platform created with the intention of <b>connecting</b> youth in need with a compatible person who can address their <b>unique needs</b> has partnered with the University of Guyana's UG cares foundation to connect the University of Guyana with those in need of aid during the current flood disaster. This serves to provide the University with an interface with the public to understand their needs and provide aid in an effective and manageable manner, boosting the ability of the University to help affected Guyanese recover.</p>
                    <br>
                    <br>
                </div>
            </div>

            <div animateOnScroll animationName="animated slideInUp" class="hide-on-init clr-row clr-justify-content-center" style="padding:5%;text-align:center;">
                <div class="clr-col-lg-4 clr-col-12">
                    <img src="../../assets/reaching.png" style="width:70%;margin:5%;height:auto;">
                   
                </div>
                <div class="clr-col-lg-7 clr-col-12 clr-align-self-center" style="padding:5%;text-align:left;">
                    <h1 style="font-weight: 500;font-size:40px;">Connect with people who can help</h1>
                    <h3>Be connected with experienced professionals from the University of Guyana looking to provide flood relief items</h3><br>

                    <button class="btn" style="color:black;border-color:black;" (click)="signup()">Find Help</button>
                </div>
            </div>


            <div animateOnScroll animationName="animated slideInUp" class="hide-on-init clr-row clr-justify-content-center" style="padding:5%;text-align:center;background-color: white;">
                <div class="clr-col-lg-4 clr-col-12">
                    <img src="../../assets/flood.png" style="width:70%;margin:5%;height:auto;">
                   
                </div>
                <div class="clr-col-lg-7 clr-col-12 clr-align-self-center" style="padding:5%;text-align:left;">
                    <h1 style="font-weight: 500;font-size:40px;">Get help to survive this disaster</h1>
                    <h3>The University of Guyana pledges to help Guyanese safely reach to the end of this crisis</h3>
                 
<br>
                    <button class="btn" style="color:black;border-color:black;" (click)="signup()">Get Help</button>
                </div>
            </div>

            <div class="clr-row " style="background-color:#0D1317;padding:5%;">
                <div class="clr-col-lg-3 footer-links clr-align-self-center" >
                    <img src="../../assets/GY-unity big.png" style="height:auto;width:50%;">

                  
                </div>

        

                <div class="clr-col-lg-3 footer-links">
                    <h4 style="font-weight:bold;color:white;">Services</h4>
                    <ul style="color:white;list-style-type: none;">
                        <li>   <a routerLink="/authentication/sign-up" style="color:white;">Sign Up</a><br></li>
                        <li><a  target="_blank" href="/authentication/sign-up" style="color:white;">Donate Now</a><br></li>
                    </ul>

                </div>
                <div class="clr-col-lg-3 footer-links">
                    <h4 style="font-weight:bold;color:white;"> Contact UG Cares</h4>
                    <ul style="color:white;list-style-type: none;">
                        <li>   <span style="color:white;"> <clr-icon class='is-solid' shape="phone-handset" style="color:white;"></clr-icon> +592-642-7314
                          </span> <br></li>
                            <li>   <span style="color:white;"> <clr-icon class='is-solid' shape="phone-handset" style="color:white;"></clr-icon>
                                +592-638-4394</span> <br></li>
                        <li>   <span style="color:white;"><clr-icon class="is-solid" shape="envelope" style="color:white;"></clr-icon> ugcares@uog.edu.gy</span></li>
                    </ul>
                 
                 
                </div>


                <div class="clr-col-lg-3 footer-links">
                    <h4 style="font-weight:bold;color:white;"> Follow Unity.GY</h4>
                    <span style="color:white;opacity: 0.9;">Follow us to get updates and announcements</span>
                    <div class="clr-break-row"></div>

                    
                
                                <a href="https://www.facebook.com/unitygy592" target="_blank" style="color:white;font-size:20px;padding:10px;"  class="fa fa-facebook"></a>
                                <a style="color:white;font-size:20px;padding:10px;" class="fa fa-instagram"></a>
                            

                       
                  
                       

                   
                </div>


            </div>

            <div class="clr-row clr-justify-content-center" style="background-color:whitesmoke;width:100%;">
                <div class="clr-col" style="text-align: center;">
                    <span style="font-weight: bold;">Unity.GY &#169; 2020 </span>
                </div>
              
             </div>

        </div>

       
    
    </div>
</clr-main-container>


