import { DifficultyStateModel } from './difficulty-state-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { DifficultyService } from 'src/app/services/difficulty.service';

import { GetAllDifficulty, GetDifficulty, UpdateDifficulty, CreateDifficulty } from './difficulty-actions';
import { tap } from 'rxjs/operators';

@State<DifficultyStateModel>({
    name:'Difficulty'
  })
@Injectable()
  export class DifficultyState{
  
    constructor(private service: DifficultyService){
  
    }
  
    @Selector()
    static difficulties(state:DifficultyStateModel){
      return state.difficulties.filter( object => object.active==true);
    }

    @Selector()
    static difficultyMethod(state:DifficultyStateModel){
      return state.difficulty;
    }
  
  
    @Action(GetAllDifficulty)
    getdifficulties({patchState}:StateContext<DifficultyStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          difficulties: result
        });
      }));
    }

    @Action(GetDifficulty)
    getdifficultyMethodById({patchState}:StateContext<DifficultyStateModel>, {id}: GetDifficulty){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          difficulty: result
        });
      }));
    }
  
    @Action(UpdateDifficulty)
    updatedifficultyMethod({dispatch}:StateContext<DifficultyStateModel>,{update}:UpdateDifficulty
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllDifficulty());
            }
          )
        )
      }

      @Action(CreateDifficulty)
      deletedifficulty({dispatch, patchState}:StateContext<DifficultyStateModel>,{create}:CreateDifficulty
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllDifficulty());
              }
            )
          )
        }
  
  
    
  
  }