import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';


import { GetAllDeliveryPerson, GetDeliveryPerson, UpdateDeliveryPerson, CreateDeliveryPerson } from './delivery-person-actions';
import { tap } from 'rxjs/operators';
import { DeliveryPersonStateModel } from './delivery-person-model';
import { DeliveryPersonService } from 'src/app/services/delivery-person.service';

@State<DeliveryPersonStateModel>({
    name:'DeliveryPerson'
  })
@Injectable()
  export class DeliveryPersonState{
  
    constructor(private service: DeliveryPersonService){
  
    }
  
    @Selector()
    static deliveryPersons(state:DeliveryPersonStateModel){
      return state.deliveryPersons.filter( object => object.active==true);
    }

    @Selector()
    static deliveryPerson(state:DeliveryPersonStateModel){
      return state.deliveryPerson;
    }
  
  
    @Action(GetAllDeliveryPerson)
    getdeliveryPersons({patchState}:StateContext<DeliveryPersonStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          deliveryPersons: result
        });
      }));
    }

    @Action(GetDeliveryPerson)
    getDeliveryPersonById({patchState}:StateContext<DeliveryPersonStateModel>, {id}: GetDeliveryPerson){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          deliveryPerson: result
        });
      }));
    }
  
    @Action(UpdateDeliveryPerson)
    updateDeliveryPerson({dispatch}:StateContext<DeliveryPersonStateModel>,{update}:UpdateDeliveryPerson
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllDeliveryPerson());
            }
          )
        )
      }

      @Action(CreateDeliveryPerson)
      deleteDeliveryPerson({dispatch, patchState}:StateContext<DeliveryPersonStateModel>,{create}:CreateDeliveryPerson
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllDeliveryPerson());
              }
            )
          )
        }
  
  
    
  
  }