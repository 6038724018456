import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable, fromEvent } from 'rxjs';
import { Delivery } from '../models/delivery';
import { retry, catchError, mapTo, retryWhen, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

 
  constructor(private http:HttpClient) { }

  errorHandl(error:any) {
  let errorMessage = '';
  if(error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
  } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  console.log(errorMessage);
  return throwError(errorMessage);
}

httpOptions={
  headers: new HttpHeaders({
    'Content-Type':'application/json'
  })
}
private onlineChanges$ = fromEvent(window, 'online').pipe(mapTo(true));
  
get isOnline() {
  return navigator.onLine;
}
  get(id:number):Observable<Delivery>{
    return this.http.get<Delivery>("https://unityugapi.unitygy.com/Delivery/"+id).pipe(
      retry(1),
      catchError(this.errorHandl)
    )

  }

  getAll():Observable<Delivery[]>{
    return this.http.get<Delivery[]>("https://unityugapi.unitygy.com/Delivery/").pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }


  
  getList():Observable<Delivery[]>{
    return this.http.get<Delivery[]>("https://unityugapi.unitygy.com/Delivery/GetDeliveryList").pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    )

  }




  accept(id: number) {
    return this.http.post<Delivery>('https://unityugapi.unitygy.com/Delivery/AcceptDelivery',JSON.stringify(id),this.httpOptions).pipe(
retry(1),
      catchError(this.errorHandl)
    )
  }

  removePerson(id: number) {
    return this.http.post<Delivery>('https://unityugapi.unitygy.com/Delivery/RemoveDeliveryPerson',JSON.stringify(id),this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }


  update(update: any, id:number):Observable<Delivery>{
    return this.http.patch<Delivery>('https://unityugapi.unitygy.com/Delivery/'+id,JSON.stringify(update),this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }


  markDelivered(update: any):Observable<Delivery>{
    return this.http.patch<Delivery>('https://unityugapi.unitygy.com/Delivery/MarkDelivery',JSON.stringify(update),this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }


  evidence(update: any, id:number):Observable<Delivery>{
    return this.http.post<Delivery>('https://unityugapi.unitygy.com/Delivery/'+id+'/Attachment',JSON.stringify(update),this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  delete(id:number):Observable<Delivery>{
    return this.http.delete<Delivery>('https://unityugapi.unitygy.com/Delivery/'+id,this.httpOptions).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

    create(create: any):Observable<Delivery>{
      return this.http.post<Delivery>('https://unityugapi.unitygy.com/Delivery/',JSON.stringify(create),this.httpOptions).pipe(
        retry(1),
        catchError(this.errorHandl)
      )
    }



}