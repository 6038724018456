export class GetDeliveryPerson{
    constructor(public id: number){
    
    }
    
    static readonly type="[DeliveryPerson] GetDeliveryPerson";
    
    }
    
    export class GetAllDeliveryPerson{
        constructor(){
        
        }
        
        static readonly type="[DeliveryPerson] GetAllDeliveryPerson";
        
        }
    
    export class UpdateDeliveryPerson{
        constructor(public update:any){
    
        }
    
        static readonly type="[DeliveryPerson] UpdateDeliveryPerson";
    }
        
    export class CreateDeliveryPerson{
        constructor(public create:any){
    
        }
    
        static readonly type="[DeliveryPerson] CreateDeliveryPerson";
    }


