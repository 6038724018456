export class GetDonor{
    constructor(public id: number){
    
    }
    
    static readonly type="[Donor] GetDonor";
    
    }

    export class Leaderboard{
        constructor(){}

        static readonly type="[Donor] Leaderboard";
    }
    
    export class GetAllDonor{
        constructor(){
        
        }
        
        static readonly type="[Donor] GetAllDonor";
        
        }
    
    export class UpdateDonor{
        constructor(public update:any, public id: number){
    
        }
    
        static readonly type="[Donor] UpdateDonor";
    }
        
    export class CreateDonor{
        constructor(public create:any){
    
        }
    
        static readonly type="[Donor] CreateDonor";
    }


