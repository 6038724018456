import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthenticationState } from '../store/authentication/authentication-state';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivateChild {

  constructor(private store:Store){ }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const authorization = this.store.selectSnapshot(AuthenticationState.authorization);
    const token = this.store.selectSnapshot(AuthenticationState.token);

    if (next.data.role.includes(authorization) && token !=undefined) {
      return true;
    }else{
      return false;
    }
  }
}
