<div
class="app-splash-screen"
[ngStyle]="{ left: windowWidth }"
*ngIf="showSplash"
>
<div class="app-splash-inner">
  <div class="app-logo"></div>
  <span class="title animated fadeInLeft" style="color: #00A140;font-weight:bold;font-size:20px;">UNITY<b style="color:#F7CE00;font-weight:bolder;">.GY</b></span>
  <br>
  <div class="progress loop">
    <progress></progress>
  </div>
  
</div>
</div>