import { MessageStateModel } from './message-state-model';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { MessageService } from 'src/app/services/message.service';
import { GetAllMessage, GetMessage, UpdateMessage, CreateMessage, SentMessages } from './message-actions';
import { tap } from 'rxjs/operators';

@State<MessageStateModel>({
    name:'Message'
  })
@Injectable()
  export class MessageState{
  
    constructor(private service: MessageService){
  
    }
  
    @Selector()
    static messages(state:MessageStateModel){
      return state.messages.filter( object => object.active==true);
    }


    @Selector()
    static sentMessages(state:MessageStateModel){
      return state.sentMessages.filter( object => object.active==true);
    }

    @Selector()
    static message(state:MessageStateModel){
      return state.message;
    }
  
  
    @Action(GetAllMessage,  {cancelUncompleted: true })
    getmessages({patchState}:StateContext<MessageStateModel>,{id}:GetAllMessage){
      return this.service.getAll(id).pipe(tap(result => {
        patchState({
          messages: result
        });
      }));
    }

    @Action(SentMessages,  {cancelUncompleted: true })
    getsentmessages({patchState}:StateContext<MessageStateModel>,{id}:SentMessages){
      return this.service.getAllSent(id).pipe(tap(result => {
        patchState({
          sentMessages: result
        });
      }));
    }

    @Action(GetMessage,  {cancelUncompleted: true })
    getmessageMethodById({patchState}:StateContext<MessageStateModel>, {id}: GetMessage){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          message: result
        });
      }));
    }
  
    @Action(UpdateMessage)
    updatemessageMethod({dispatch}:StateContext<MessageStateModel>,{update}:UpdateMessage
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
            
            }
          )
        )
      }

      @Action(CreateMessage)
      deletemessage({dispatch, patchState}:StateContext<MessageStateModel>,{create}:CreateMessage
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
              
              }
            )
          )
        }
  
  
    
  
  }