import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';



import { tap } from 'rxjs/operators';
import { AddressService } from 'src/app/services/address.service';
import { AddressStateModel } from './address-model';
import { GetAllAddress, GetAddress, UpdateAddress, CreateAddress } from './address-actions';


@State<AddressStateModel>({
    name:'Address'
  })
@Injectable()
  export class AddressState{
  
    constructor(private service: AddressService){
  
    }
  
    @Selector()
    static addresses(state:AddressStateModel){
      return state.addresses.filter( object => object.active==true);
    }

    @Selector()
    static Address(state:AddressStateModel){
      return state.address;
    }
  
  
    @Action(GetAllAddress,  {cancelUncompleted: true })
    getaddresses({patchState}:StateContext<AddressStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          addresses: result
        });
      }));
    }

    @Action(GetAddress,  {cancelUncompleted: true })
    getAddressMethodById({patchState}:StateContext<AddressStateModel>, {id}: GetAddress){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          address: result
        });
      }));
    }
  
    @Action(UpdateAddress)
    updateAddressMethod({dispatch}:StateContext<AddressStateModel>,{update}:UpdateAddress
      ){
        return this.service.update(update).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllAddress());
            }
          )
        )
      }

      @Action(CreateAddress)
      createAddress({dispatch, patchState}:StateContext<AddressStateModel>,{create}:CreateAddress
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllAddress());
              }
            )
          )
        }
  
  
    
  
  }