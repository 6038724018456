export class GetDelivery{
    constructor(public id: number){
    
    }
    
    static readonly type="[Delivery] GetDelivery";
    
    }

    export class AcceptDelivery{
        constructor(public id: number){
        
        }
        
        static readonly type="[Delivery] AcceptDelivery";
        
        }
        
    
    export class GetAllDelivery{
        constructor(){
        
        }
        
        static readonly type="[Delivery] GetAllDelivery";
        
        }


        export class GetDeliveryList{
            constructor(){
            
            }
            
            static readonly type="[Delivery] GetAllDeliveryList";
            
            }
    
    export class UpdateDelivery{
        constructor(public update:any,public id:number){
    
        }
    
        static readonly type="[Delivery] UpdateDelivery";
    }


    export class MarkDelivery{
        constructor(public update:any){
    
        }
    
        static readonly type="[Delivery] MarkDelivery";
    }

    export class EvidenceUpload{
        constructor(public update:any,public id:number){
    
        }
    
        static readonly type="[Delivery] Evidence";
    }

    export class RemoveDeliveryPerson{
        constructor(public id:number){
    
        }
    
        static readonly type="[Delivery] RemoveDeliveryPerson";
    }



    export class DeleteDelivery{
        constructor(public id:number){
    
        }
    
        static readonly type="[Delivery] DeleteDelivery";
    }
        
    export class CreateDelivery{
        constructor(public create:any){
    
        }
    
        static readonly type="[Delivery] CreateDelivery";
    }


