
<clr-header style="background-color:#0D1317;">
        <div class="branding" >
           
     
                <a href="..." routerLink="/timeline" class="nav-link" >
                    <img src="../../assets/GY-unity.png" style="height: inherit;width:auto;padding:5px;">
                  
                </a>
                <span class="title" style="color: #00A140;font-weight:bold;">UNITY<b style="color:#F7CE00;font-weight:bolder;">.GY</b></span>

        </div>

        <div class="header-nav" [clr-nav-level]="1">
            <a href="..." class="nav-link" routerLink="/timeline" routerLinkActive="active"><span class="nav-text">Timeline</span></a>
            <a href="..." class="nav-link" routerLink="/tasks" routerLinkActive="active" *ngIf="role !='DeliveryPerson' && role!='Admin'"><span class="nav-text">Requests</span></a>
            <a href="..." class="nav-link" routerLink="/referrals" *ngIf="role =='Donee'" routerLinkActive="active"><span class="nav-text">Referrals</span></a>
            <a href="..." class="nav-link" routerLink="/leaderboard" routerLinkActive="active"><span class="nav-text">Leaderboard</span></a>
            <a href="..." class="nav-link" routerLink="/delivery"  *ngIf="role =='Donor' || role =='DeliveryPerson'" routerLinkActive="active"><span class="nav-text">Delivery</span></a>
            <a href="..." class="nav-link" routerLink="/rules" routerLinkActive="active"><span class="nav-text">Guide</span></a>
        </div>

        <div class="header-actions">
            <a href=".." routerLink="/messages" class="nav-link" routerLinkActive="active">

                <div style="position:relative;width:100%;height:100%;">
                    <clr-icon shape="chat-bubble" style="position:absolute;bottom:0;"></clr-icon> <span style="position:absolute;top:0;left:0;background-color:red;" *ngIf="unreadMessages.length"  class="badge" >{{unreadMessages.length}}</span>
            </div>
               
            </a>
            <clr-dropdown>
            <button class="nav-text" clrDropdownTrigger aria-label="open user profile">
                <clr-icon shape="user" class="is-solid"></clr-icon>

                <clr-icon shape="caret down" class="is-solid"></clr-icon>
            </button>
            <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                <button clrDropdownItem routerLink="/authentication/my-account">
                   My Account
                </button>
                <button clrDropdownItem (click)="logout()">
                    Log out
                </button>
            </clr-dropdown-menu>
        </clr-dropdown>
        </div>
</clr-header>


<clr-modal [(clrModalOpen)]="finishSignUp" [clrModalClosable]="false" >
    <h3 class="modal-title">Complete Sign Up</h3>
    <div class="modal-body">
        <p>To finish the sign up process you must enter the relevant user data for matching you with a potential donor/donee.
            Press 'Add Details' below and update your account data to finish the signup process.
        </p>
        <div class="modal-footer">
            <button routerLink="/authentication/my-account" class="btn btn-primary">Add Details</button>
        </div>
  </div>
</clr-modal>




  