<clr-main-container>
    <app-header></app-header>
    <div class="content-container" >
        <clr-vertical-nav [clr-nav-level]="2">
            <a clrVerticalNavLink routerLink="/referrals/my-referrals" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="user" class="is-solid"></clr-icon>
              My Referrals
            </a>
            <a clrVerticalNavLink routerLink="/referrals/create-referral" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="list" class="is-solid"></clr-icon>
                Create Referrals
            </a>
        </clr-vertical-nav>
        <div class="content-area">
            <router-outlet></router-outlet>
        </div>
    </div>
</clr-main-container>
